/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import 'codemirror/lib/codemirror';
@import 'codemirror/theme/material';
@import 'material-icons/iconfont/material-icons.css';

.CodeMirror {
  resize: vertical;
}

*[hidden] {
  visibility: hidden;
}

.mat-snack-bar-container.errorMessage {
  border: 2px solid rgba(97, 0, 0, 0.5);
  background: rgba(168, 0, 10, 0.77);
  color: white;
}

.mat-snack-bar-container.goodMessage {
  border: 2px solid rgba(32, 142, 0, 0.5);
  background: rgba(38, 194, 0, 0.77);
  color: white;
}

.mat-autocomplete-panel.mat-autocomplete-visible {
  background: white;
}

.mat-form-field-disabled.hide-when-disabled {
  display: none;
}

.bg-oa-image {
  background-image: url('/assets/images/ui/OpenApp_background.jpg');
  background-position-x: 50%;
  background-size: cover;
}

.bg-oa-gradient {
  background: rgb(98,255,255);
  background: linear-gradient(40deg, rgba(98,255,255,1) 4%, rgba(0,235,255,1) 10%, rgba(0,213,255,1) 15%, rgba(0,189,255,1) 21%, rgba(0,163,255,1) 28%, rgba(101,153,255,1) 35%, rgba(149,141,247,1) 44%, rgba(187,127,232,1) 52%, rgba(221,141,217,1) 62%, rgba(240,160,208,1) 72%, rgba(248,183,206,1) 80%, rgba(249,207,213,1) 95%); 
}

@media (max-width: 600px) {
  .bg-oa-gradient {
    background: transparent;
  }
}

.visibility_off {
  visibility: hidden;
}